<template>
  <div class="content">
    <ActionsHeader
        pageType="PreviewContest"
        @goBack="goToCompetitionPreview"
        @choice="dropDownChoice"
        :dropDownActions="dropDownActions"
    />
    <!-- Settings -->
    <CRow>
      <CCol col="12">
        <CCard class="zq--wizard-card" v-if="ready">
          <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
            <div class="d-flex justify-content-between">
              <strong class="title">{{ texts.previewPage.title }}</strong>
              <CLink class="card-header-action btn-minimize">
                <ClCardArrow :is-collapsed="basicIsCollapsed" />
              </CLink>
            </div>
          </CCardHeader>
          <CCollapse :show="basicIsCollapsed" :duration="400">
            <CCardBody>
              <ZqFieldsView :texts="texts.previewPage" :formData="formData" :modelFields="modelFields" />
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
    <!-- Scheduling  -->
    <Scheduling v-if="ready" creationType="contest" :schedulingData="schedulingData" />
    <!--  Rewards  -->
    <ViewRewards v-if="ruleModels.includes(model)" :entityId="entityId" />
    <!--  Rules  -->
    <ViewRules
        v-if="ruleModels.includes(model)"
        :entityId="entityId"
        :context="'contest'"
    />
    <!--  Languages  -->
    <ViewTranslations
        :entityData="formData"
        :entityId="formData.id"
    />
    <Modal
      :modalShow="exportModal"
      :messageGeneral="exportMessage"
      :title="texts.exportTitle"
      @doFunction="exportEntrants"
      v-on:toggle-modal="exportModal = false"
    />
  </div>
</template>

<script>
import ActionsHeader from '../components/ActionsHeader';
import Scheduling from '@/views/ziqni/competitions/component/steps/Summary/components/Scheduling';
import ViewRewards from '@/shared/components/supportModels/rewards/ViewRewards';
import ViewTranslations from '@/shared/components/supportModels/translations/ViewTranslations';
import ViewRules from '@/shared/components/supportModels/rules/ViewRules';
import { mapActions, mapGetters } from 'vuex';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import ZqFieldsView from '@/shared/components/ZqFieldsView';
import { contestsTexts } from "@/config/pageTexts/contests.json";
import fields from "@/generated/ziqni/store/modules/contests/fields";
import { cloneDeep } from "lodash";
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import Modal from "@/shared/UI/Modal.vue";
import axios from "axios";

export default {
  components: {
    Modal,
    ActionsHeader,
    Scheduling,
    ViewRules,
    ViewRewards,
    ViewTranslations,
    ClCardArrow,
    ZqFieldsView,
  },
  props: {
    contest: Object,
    contestId: String,
  },
  data() {
    return {
      model: 'contest',
      entityId: '',
      ready: false,
      exportModal: false,
      exportMessage: '',
      basicIsCollapsed: true,
      texts: {
        ...contestsTexts,
      },
      modelFields : {
        ...fields,
      },
      settingsData: {
        name: '',
        description: '',
        termsAndConditions: '',
        tags: [],
        metadata: []
      },
      translationsData: {},
      rulesData: {},
      rewardsData: [],
      baseData: {},
      schedulingData: {
        scoreDesc: false,
        scoreFirst: false,
        ignoreTime: false,
        ignoreScore: false,
        timeDesc: false,
        excludeIfGoalNotReached: false,
        round: 1,
        entrantsFromContest: [],
        strategies: {
          strategyType: "",
          rankingStrategy: {
            constraints: []
          },
          scoringStrategy: {
            limitUpdatesTo: 0,
            sumBestXOf: 0,
            lastUpdateTimeStamp: 0,
            recordTimeWhenSumReaches: 0
          }
        },
      },
      formData: {},
      ruleModels: ['achievement', 'contest'],
      dropDownActions: [
        {
          title: this.$t('buttons.edit'),
          handler: 'editContest',
        },
        {
          title: this.$t('buttons.exportEntrants'),
          handler: 'openExportModal',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('competitions', ['competition']),
    ...mapGetters('contests', { contestData: 'contest' }),
  },
  created() {
    this.initData();
  },
  watch: {
    contestData: {
      deep: true,
      handler: function (val) {
        delete val.groupStage;
        delete val.groupStageLabel;
        delete val.row;
        this.formData = cloneDeep(val);
        this.formData.minNumberOfEntrants = val.minNumberOfEntrants && val.minNumberOfEntrants !== 0
            ? val.minNumberOfEntrants
            : 'Unlimited';
        this.formData.maxNumberOfEntrants = val.maxNumberOfEntrants && val.maxNumberOfEntrants < 1000000000
            ? val.maxNumberOfEntrants
            : 'Unlimited';

        this.schedulingData.strategies.strategyType = val.strategies.strategyType;
        this.schedulingData.strategies.scoringStrategy = val.strategies.scoringStrategy;
        this.schedulingData.round =  val.round;
        this.schedulingData.entrantsFromContest =  val.entrantsFromContest ?? [];
        this.schedulingData.strategies.rankingStrategy = val.strategies.rankingStrategy;
      },
    },
  },
  methods: {
    ...mapActions('rules', ['handleGetRules']),
    ...mapActions('rules', ['handleGetRulesByQuery']),
    ...mapActions('rewards', ['handleGetRewardsByContestId']),
    ...mapActions('contests', ['handleGetContests_item', 'handleUpdateContestsState', 'handleGetContestsByQuery']),
    ...mapActions('competitions', ['handleGetCompetitions_item']),
    ...mapActions('entrants', ['handleExportEntrantsByQuery']),
    goToCompetitionPreview() {
      this.$router.push({
        name: 'PreviewCompetition',
        params: {
          id: this.$route.params.id,
        },
        query: {
          isContest: true
        }
      }).catch(()=>{});
    },
    editContest() {
      this.$router.push({
        name: 'EditContest',
        params: {
          id: this.entityId,
        },
      });
    },
    async openExportModal() {
      const exportData = await this.handleExportEntrantsByQuery({
        queryRequest: {
          must: [
            {
              queryField: 'participationId',
              queryValues: [this.entityId]
            }
          ]
        }
      });

      this.exportMessage = `You are about to download ${exportData.numberOfRecords} rows of data`

      this.exportModal = true;
    },
    async exportEntrants() {
      this.exportModal = false;
      const exportData = await this.handleExportEntrantsByQuery({
        queryRequest: {
          must: [
            {
              queryField: 'participationId',
              queryValues: [this.entityId]
            }
          ]
        }
      });

      if (exportData.numberOfRecords > 0) {
        const token = localStorage.getItem('vue-token');
        const csv = await axios.get(exportData.downloadUrl, {
          headers: {"Authorization" : `Bearer ${token}`},
          responseType: 'text/csv'
        });

        const blob = new Blob([csv.data], {type : 'text/csv'});
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);
        link.download = this.formData.name + '_entrants';
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
    async initData() {
      const contestId = this.contestId ?? this.$route.params.contestId;
      this.entityId = contestId;

      const val = await this.handleGetContests_item([contestId],1,0);
      this.$emit('setContestStatus', val[0].status)

      if (!this.competition.name) {
        await this.handleGetCompetitions_item([this.$route.params.id],1,0)
      }

      routerBreadcrumbs(this.$router.currentRoute, {
        competitionId: this.$route.params.id,
        competitionName: this.competition.name,
        name: val[0].name,
        spaceName: val[0].spaceName,
      });

      this.setDropDownActions(val[0]?.status);

      this.ready = true;
    },
    setDropDownActions(status) {
      switch (status) {
        case 'Ready':
          return this.setReadyContestActions();
        case 'Active':
          return this.setActiveContestActions();
        case 'Finished':
          return this.setFinishedContestActions();
        case 'Finalised':
          return this.setFinalisedContestActions();
        default:
          return this.dropDownActions;
      }
    },
    setActiveContestActions() {
      this.dropDownActions = [
        {
          title: 'Finish',
          handler: 'finishedContest',
        },
        {
          title: 'Cancel',
          handler: 'cancellingContest',
        },
        {
          title: 'Delete',
          handler: 'deleteContest',
        },
        ...this.dropDownActions
      ];
    },
    setReadyContestActions() {
      this.dropDownActions = [
        {
          title: 'Start',
          handler: 'startingContest',
        },
        {
          title: 'Cancel',
          handler: 'cancellingContest',
        },
        {
          title: 'Delete',
          handler: 'deleteContest',
        },
        ...this.dropDownActions
      ];
    },
    setFinishedContestActions() {
      this.dropDownActions = [
        {
          title: 'Finalise',
          handler: 'finalisedContest',
        },
        {
          title: 'Cancel',
          handler: 'cancellingContest',
        },
        // {
        //   title: 'Delete',
        //   handler: 'deleteContest',
        // },
        ...this.dropDownActions
      ];
    },
    dropDownChoice(action) {
      if (this[action]) {
        this[action]();
      }
    },
    setFinalisedContestActions() {
      this.dropDownActions = [
        {
          title: 'Cancel',
          handler: 'cancellingContest',
        },
        // {
        //   title: 'Delete',
        //   handler: 'deleteContest',
        // },
        ...this.dropDownActions
      ];
    },
    async startingContest() {
      const updateContestStateRequestArray = [{ contestId: this.contestData.id, status: 'Active' }];
      await this.handleUpdateContestsState({updateContestStateRequestArray});
    },
    async cancellingContest() {
      const updateContestStateRequestArray = [{ contestId: this.contestData.id, status: 'Cancelled' }];
      await this.handleUpdateContestsState({updateContestStateRequestArray});
    },
    async finishedContest() {
      const updateContestStateRequestArray = [{ contestId: this.contestData.id, status: 'Finished' }];
      await this.handleUpdateContestsState({updateContestStateRequestArray});
    },
    async finalisedContest() {
      const updateContestStateRequestArray = [{ contestId: this.contestData.id, status: 'Finalised' }];
      await this.handleUpdateContestsState({updateContestStateRequestArray});
    },
    async deleteContest() {
      const updateContestStateRequestArray = [{ contestId: this.contestData.id, status: 'Deleted' }];
      await this.handleUpdateContestsState({updateContestStateRequestArray});
    },
  },
}
</script>
