<template>
  <div class="content">
    <div class="leaderboard--actions-header">
      <CButton
        size="sm"
        class="header-icon-buttons mr-3"
        @click.stop.prevent="handleReload"
      >
        <CIcon name="cil-reload"/>
      </CButton>
    </div>
    <CRow class="mt-1">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="leaderboardTableId"
              class="zq--table"
              :items="tableData"
              :fields="tableFields"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="!ready"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              @update:sorter-value="handleSortBy"
              :sorter-value.sync="sorterValue"
            >
              <template #loading>
                <TableLoader />
              </template>
              <template #members="{ item }">
                <td class="text-center">
                  <a href="#" v-for="member in item.members" @click.prevent="showMember(member)">
                    {{ member.name }}
                  </a>
                </td>
              </template>
              <template #memberRefIds="{ item }">
                <td class="text-center">
                  <a href="#" v-for="member in item.members" @click.prevent="showMember(member)">
                    {{ member.memberRefId }}
                  </a>
                </td>
              </template>
              <template #memberIds="{ item }">
                <td class="text-center">
                  <a href="#" v-for="member in item.members" @click.prevent="showMember(member)">
                    {{ member.memberId }}
                  </a>
                </td>
              </template>
              <template #rewards="{ item }">
                <td class="text-center">
                  {{ getRewards(item) }}
                </td>
              </template>
            </CDataTable>
            <TableFooter
              :page="page"
              @updatePagenation="paginationChange"
              :pages="getPages"
              :total="totalRecords"
              :itemsPerPage="itemsPerPage"
              :disabled="!ready"
              @updateItemPerPage="itemsPerPageSelect"
              :key="footerReloadKey"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <Modal
      :modalShow="isModal"
      :messageGeneral="modalText"
      :title="modalTitle"
      :isSuccessButton="false"
      v-on:toggle-modal="isModal = false"
    />
  </div>
</template>

<script>
import TableLoader from '@/components/table/Loader';
import TableFooter from '@/components/table/Footer';
import {competitionsTexts} from "@/config/pageTexts/competitions";
import {mapActions, mapGetters} from "vuex";
import {pageConfig} from "@/config";
import Modal from "@/shared/UI/Modal.vue";
import {cloneDeep} from "lodash";

export default {
  name: "ContestLeaderboard",
  components: {
    Modal,
    TableLoader,
    TableFooter,
  },
  data() {
    return {
      ready: false,
      isModal: false,
      footerReloadKey: 1,
      modalTitle: competitionsTexts.leaderboardDetail.modalTitle,
      modalText: '',
      texts: {
        ...competitionsTexts
      },
      leaderboardIsCollapsed: false,
      leaderboardData: null,
      leaderboardEntriesData: null,
      tableData: [],
      tableFields: [
        'rank',
        'members',
        'memberRefIds',
        'memberIds',
        'score',
        'bestScores',
        'rewards'
      ],
      // Rank, Name, mem ref id, member id, points, rewards
      columnFilter: false,
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      pages: 1,
      sorter: null,
      order: null,
      isLeaderboardActive: false,
      isContestActive: false,
      intervalId: false,
      viewRewardsData: []
    }
  },
  props: {
    contestId: String,
  },
  computed: {
    ...mapGetters('contests', ['contest', 'loading']),
    ...mapGetters('theme', ['theme']),
    ...mapGetters('leaderboard', ['totalRecords', 'loading']),
    isDefaultTheme() {
      return this.theme === 'default';
    },
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc',
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        };
      },
    },
    getPages() {
      return isNaN(this.pages) ? 1 : this.pages;
    },
  },
  async created() {
    const val = await this.handleGetContests_item([this.contestId],1,0);
    this.isContestActive = val[0].status === 'Active';

    await this.handleGetLeaderboardById({
      id: this.contestId,
      limit: 0,
      skip: 0
    });
    this.isLeaderboardActive = true;
    await this.queryDb();
    this.ready = true;
  },
  beforeDestroy() {
    this.isLeaderboardActive = false;
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions('leaderboard', ['handleGetLeaderboardById']),
    ...mapActions('contests', ['handleGetContests_item']),
    ...mapActions('rewards', ['handleGetRewardsByQuery']),
    async handleReload() {
      await this.queryDb();
    },
    async queryDb() {
      this.viewRewardsData = await this.handleGetRewardsByQuery(
        {
          queryRequest: {
            should: [{
              queryField: 'entityId',
              queryValues: [this.contestId]
            }],
            shouldMatch: 1
          }
        }
      );

      this.leaderboardData = await this.handleGetLeaderboardById({
        id: this.contestId,
        limit: this.page * this.itemsPerPage,
        skip: (this.page - 1) * this.itemsPerPage
      });

      this.leaderboardEntriesData = this.leaderboardData[0] && this.leaderboardData[0].leaderboardEntries
        ? this.leaderboardData[0].leaderboardEntries
        : [];

      if (this.totalRecords) {
        this.leaderboardEntriesData.forEach((item) => {
          item.members = item.members.map(i => {
            return {name: i.name, memberId: i.memberId, memberRefId: i.memberRefId, rank: item.rank, score: item.score, rewards: item.rewards};
          });
          return item;
        });
        this.setPages();
      }

      if (this.isLeaderboardActive && this.isContestActive && !this.intervalId) {
        this.intervalId = setInterval(() => {
          this.queryDb();
        }, 5 * 1000);
      }
    },
    getRewards(item) {
      const rank = item.rank;
      let rewardValue = '-';

      if (this.viewRewardsData && this.viewRewardsData.length) {
        const idx = this.viewRewardsData.findIndex(reward => {
          if (reward.rewardRank.indexOf('-') !== -1) {
            const idx = reward.rewardRank.indexOf('-');
            const start = parseInt(reward.rewardRank);
            const end = parseInt(reward.rewardRank.substring(idx + 1));
            if (rank >= start && rank <= end) {
              return true;
            }
          } else {
            return Number(reward.rewardRank) === Number(rank)
          }
        });

        if (idx !== -1) {
          rewardValue = this.viewRewardsData[idx].rewardValue;
        }
      }

      return rewardValue;
    },
    showMember(member) {
      this.modalText = `
        <p><b>Id:</b> <a href="#/members/detail/${member.memberId}">${member.memberId}</a></p>
        <p><b>Name:</b> ${member.name}</p>
        <p><b>ReferenceId:</b> ${member.memberRefId}</p>
        <p><b>Rank:</b> ${member.rank}</p>
        <p><b>Score:</b> ${member.score}</p>
      `;
      this.isModal = true;
    },
    async itemsPerPageSelect(val) {
      this.ready = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.setPages();
      await this.queryDb();
      //this.footerReloadKey++;
      this.ready = true;
    },
    paginationChange(val) {
      this.page = val;
      this.queryDb()
    },
    async handleSortBy(e) {
      this.sorter = e.column;
      this.order = e.asc ? 'Asc' : 'Desc';
      this.leaderboardEntriesData.sort((a, b) => {
        if (a[this.sorter] < b[this.sorter]) return this.order === 'Asc' ? -1: 1;
        if (a[this.sorter] > b[this.sorter]) return this.order === 'Asc' ? 1: -1;
        return 0;
      });
      this.page = 1;
      this.setPages();
    },
    setPages() {
      this.pages = Math.ceil(this.totalRecords / this.itemsPerPage);
      this.tableData = this.leaderboardEntriesData.slice(0, this.itemsPerPage);
    },
  },
}
</script>

<style scoped lang="scss">
.leaderboard {
  &--actions-header {
    display: flex;
    justify-content: flex-end;
  }
}
.zq--wizard-card {
  &__top {
    z-index: 99;
  }
  .header-collapse-row {
    position: relative;

    .reload-btn {
      display: flex;
      align-items: center;
      position: absolute;
      top: 10%;
      right: 50px;

      & > svg {
        margin-left: 5px;
      }
    }
  }
}
</style>